import { ControlCommunicationService } from './../../../form-builder/services/control-communication.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthenticationService, RequestsService, ReviewsService, EvaluationsService, UsersService, InspectionsService } from '@app/core/services'
import { DomSanitizer } from '@angular/platform-browser'
import { first } from 'rxjs/operators';
import { Packer } from "docx";
import { Router } from '@angular/router';


//Documents version 1
import { DocumentCreatorMTO_FSOL_01_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_01' //ID: 0
import { DocumentCreatorMTO_FSOL_02_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_02' //ID: 1
import { DocumentCreatorMTO_FSOL_04_1_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_04_1' //ID: 2, ID: 52 for inspection
import { DocumentCreatorMTO_FSOL_04_2_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_04_2' //ID: 16, ID: 57 for inspection
import { DocumentCreatorMTO_FSOL_05_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_05' //ID: 3
import { DocumentCreatorMTO_FSOL_06_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_06' //ID: 4
import { DocumentCreatorMTO_FSOL_07_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_07' //ID: 30 (review how form)
import { DocumentCreatorMTO_FSOL_08_1_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_08_1' //ID: 12
import { DocumentCreatorMTO_FSOL_08_2_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_08_2' //ID: 22
import { DocumentCreatorMTO_FSOL_08_3_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_08_3' //ID: 18
import { DocumentCreatorMTO_FSOL_08_4_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_08_4' //ID: 24
import { DocumentCreatorMTO_FSOL_08_5_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_08_5' //ID: 25
import { DocumentCreatorMTO_FSOL_08_7_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_08_7' //ID: 23
import { DocumentCreatorMTO_FSOL_09_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_09' //ID: 14
import { DocumentCreatorMTO_FSOL_10_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_10' //ID: 9
import { DocumentCreatorMTO_FSOL_11_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_11' //ID: 10
import { DocumentCreatorMTO_FSOL_14_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_14' //ID: 11
import { DocumentCreatorMTO_FSOL_23_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_23' //ID: 32 (review how form)
import { DocumentCreatorMTO_FSOL_24_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_24' //ID: 17
import { DocumentCreatorMTO_FRYD_03_v1 } from '@app/features/generator/V1/docx.MTO_FRYD_03' //ID: without ID, the information get it of endpoint in the view "Orden de inspección"
import { DocumentCreatorMTO_FINS_01_v1 } from '@app/features/generator/V1/docx.MTO_FINS_01' //ID: 40
import { DocumentCreatorMTO_FINS_02_v1 } from '@app/features/generator/V1/docx.MTO_FINS_02' //ID: 50
import { DocumentCreatorMTO_FRYD_05_v1 } from '@app/features/generator/V1/docx.MTO_FRYD_05' //ID: 68
import { DocumentCreatorMTO_FSOL_08_1_INSPECTION_v1 } from "@app/features/generator/V1/docx.MTO_FSOL_08_1_inspection" //ID: 51
import { DocumentCreatorMTO_FSOL_08_2_INSPECTION_v1 } from "@app/features/generator/V1/docx.MTO_FSOL_08_2_inspection" //ID: 54
import { DocumentCreatorMTO_FSOL_08_3_INSPECTION_v1 } from "@app/features/generator/V1/docx.MTO_FSOL_08_3_inspection" //ID: 56
import { DocumentCreatorMTO_FSOL_08_4_INSPECTION_v1 } from "@app/features/generator/V1/docx.MTO_FSOL_08_4_inspection" //ID: 59
import { DocumentCreatorMTO_FSOL_08_5_INSPECTION_v1 } from "@app/features/generator/V1/docx.MTO_FSOL_08_5_inspection" //ID: 61
import { DocumentCreatorMTO_FSOL_08_7_INSPECTION_v1 } from "@app/features/generator/V1/docx.MTO_FSOL_08_7_inspection" //ID: 63
import { DocumentCreatorMTO_FINS_03_1_v1 } from '@app/features/generator/V1/docx.MTO_FINS_03_1' //ID: 53
import { DocumentCreatorMTO_FINS_03_2_v1 } from '@app/features/generator/V1/docx.MTO_FINS_03_2' //ID: 55
import { DocumentCreatorMTO_FINS_03_3_v1 } from '@app/features/generator/V1/docx.MTO_FINS_03_3' //ID: 58
import { DocumentCreatorMTO_FINS_03_4_v1 } from '@app/features/generator/V1/docx.MTO_FINS_03_4' //ID: 60
import { DocumentCreatorMTO_FINS_03_5_v1 } from '@app/features/generator/V1/docx.MTO_FINS_03_5' //ID: 62
import { DocumentCreatorMTO_FINS_03_7_v1 } from '@app/features/generator/V1/docx.MTO_FINS_03_7' //ID: 64
import { DocumentCreatorMTO_FINS_04_v1 } from '@app/features/generator/V1/docx.MTO_FINS_04'; //ID: 65 cuando se esta generando el hallazgo, el ID es 68
import { DocumentCreatorMTO_FINS_06_v1 } from '@app/features/generator/V1/docx.MTO_FINS_06'; //ID: 66 cuando se cargan los resultados de laboratiorio, el ID es 91

//Documents version 2
import { DocumentCreatorMTO_FSOL_01_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_01' //ID: 200
import { DocumentCreatorMTO_FSOL_01_1_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_01_1' //ID: without ID, it's generated if document with ID 200 exist
import { DocumentCreatorMTO_FSOL_02_1_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_02_1' //ID: 201
import { DocumentCreatorMTO_FSOL_02_2_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_02_2' //ID: 279
import { DocumentCreatorMTO_FSOL_04_1_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_04_1' //ID: 202, ID: 252 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_04_2_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_04_2' //ID: 270, ID: 285 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_04_3_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_04_3' //ID: 216, ID: 257 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_05_1_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_05_1' //ID: 203
import { DocumentCreatorMTO_FSOL_05_3_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_05_3' //ID: 271
import { DocumentCreatorMTO_FSOL_06_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_06' //ID: 204
import { DocumentCreatorMTO_FSOL_07_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_07' //ID: 230 (review how form)
import { DocumentCreatorMTO_FSOL_08_1_1_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_1_1' //ID: 212, ID: 251 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_1_2_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_1_2' //ID: 272, ID: 286 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_1_3_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_1_3' //ID: 273, ID: 287 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_1_4_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_1_4' //ID: 224, ID: 259 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_1_5_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_1_5' //ID: 225, ID: 261 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_2_1_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_2_1' //ID: 222, ID: 254 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_2_3_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_2_3' //ID: 223, ID: 263 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_2_4_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_2_4' //ID: 275, ID: 289 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_3_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_3' //ID: 218, ID: 256 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_4_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_4' //ID: 276, ID: 290 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_09_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_09' //ID: 214
import { DocumentCreatorMTO_FSOL_10_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_10' //ID: 209
import { DocumentCreatorMTO_FSOL_11_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_11' //ID: 210
import { DocumentCreatorMTO_FSOL_12_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_12' //ID: 220
import { DocumentCreatorMTO_FSOL_13_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_13' //ID: 221
import { DocumentCreatorMTO_FSOL_14_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_14' //ID: 211
import { DocumentCreatorMTO_FSOL_21_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_21' //ID: 278
import { DocumentCreatorMTO_FSOL_23_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_23' //ID: 232 (review how form)
import { DocumentCreatorMTO_FSOL_24_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_24' //ID: 217
import { DocumentCreatorMTO_FRYD_03_v2 } from '@app/features/generator/V2/docx.MTO_FRYD_03' //ID: without ID, the information get it of endpoint in the view "Orden de inspección"
import { DocumentCreatorMTO_FINS_01_v2 } from '@app/features/generator/V2/docx.MTO_FINS_01' //ID: 240
import { DocumentCreatorMTO_FINS_02_v2 } from '@app/features/generator/V2/docx.MTO_FINS_02' //ID: 250
import { DocumentCreatorMTO_FRYD_05_v2 } from '@app/features/generator/V2/docx.MTO_FRYD_05' //ID: 268
import { DocumentCreatorMTO_FRYD_01 } from '@app/features/generator/V2/docx.MTO_FRYD_01'    //ID: 293
import { DocumentCreatorMTO_FINS_03_1_1_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_1_1' //ID: 253
import { DocumentCreatorMTO_FINS_03_1_2_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_1_2' //ID: 280
import { DocumentCreatorMTO_FINS_03_1_3_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_1_3' //ID: 281
import { DocumentCreatorMTO_FINS_03_1_4_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_1_4' //ID: 260
import { DocumentCreatorMTO_FINS_03_1_5_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_1_5' //ID: 262
import { DocumentCreatorMTO_FINS_03_2_1_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_2_1' //ID: 255
import { DocumentCreatorMTO_FINS_03_2_3_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_2_3' //ID: 264
import { DocumentCreatorMTO_FINS_03_2_4_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_2_4' //ID: 283
import { DocumentCreatorMTO_FINS_03_3_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_3' //ID: 258
import { DocumentCreatorMTO_FINS_03_4_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_4' //ID: 284
import { DocumentCreatorMTO_FINS_04_v2 } from '@app/features/generator/V2/docx.MTO_FINS_04'; //ID: 265 cuando se esta generando el hallazgo, el ID es 268
import { DocumentCreatorMTO_FINS_06_v2 } from '@app/features/generator/V2/docx.MTO_FINS_06'; //ID: 266 cuando se cargan los resultados de laboratiorio, el ID es 291
import { Subscription } from 'rxjs';


@Component({
    selector: 'modal-preview',
    templateUrl: 'modal-preview.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalPreview implements OnInit, OnDestroy {
    form: FormGroup;
    formOrder: FormGroup;
    @Input() public name = ''
    @Input() public formId = null
    @Input() public reviewId
    @Input() public isEvaluation = false
    @Input() public isInspection = false
    @Input() public isAnInspectionDoc = false
    @Input() public isChecklist = false
    @Input() public showReview = true
    @Input() public showEval = false
    @Input() public toSave = false
    @Input() public isOrderInspection = false
    @Input() public inspectionId = 0
    @Input() public isAcceptedOrder = false
    @Input() public signatureUrl = ''
    @Input() public signatureUrl2 = ''
    @Input() public isNC = false
    @Input() public indexRFYD = 0
    @Input() public dictator = ''
    @Input() public isFinding = false
    @Input() public urlFinding = ''
    @Input() public isResultReview = false
    @Input() public isResultReviewVersion = '1'
    @Input() public dataResult = null
    @Input() public isContract = false
    @Input() public urlContract = ''
    @Input() public isApplication = false

    loading = false;
    docloading = true
    submitted = false;
    res = false
    reviewers = []
    forbiddenId = false;
    submission = null
    requirements = null
    response = null
    showForm = false
    showDoc = false
    bdoc
    url
    saving = false

    attachments = []

    title = ''
    data
    private subscriptions: Subscription

    constructor(
        private formBuilder: FormBuilder,
        public modal: NgbActiveModal,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private reviewsService: ReviewsService,
        private evaluationService: EvaluationsService,
        private sanitizer: DomSanitizer,
        private communication: ControlCommunicationService,
        private inspectionService: InspectionsService,
        private users: UsersService,
        private router: Router,
        private requestsService: RequestsService
    ) {
        this.subscriptions = this.communication.changeEmmited$.subscribe(async msg => {
            //console.log(msg.identifier, this.saving)
            if (msg.identifier === 'send-form' && !this.saving) {
                const formGroup = msg.form as FormGroup
                const f = formGroup.value
                var data = f
                for (var i in this.requirements) {
                    const requirement = this.requirements[i]
                    if (Object.keys(requirement.data).length > 0) {
                        if (requirement.isDynamic) {
                            let form = data[requirement.requiredInto][0]
                            let nval = []
                            for (var j in requirement.data) {
                                const r = requirement.data[j]
                                let s = {}
                                // TODO: CHECAR ESTO !!
                                // for(var j in f[requirement.requiredInto]) {
                                //     const elem = f[requirement.requiredInto][j]
                                //     for (var k in Object.keys(r)) {
                                //         if (elem['nombre_unidad_produccion'] == r['nombre_unidad_produccion']) {
                                //             s = elem
                                //         }
                                //     }
                                // }
                                for (var k in Object.keys(r)) {
                                    const key = Object.keys(r)[k]
                                    s[key] = r[key]
                                }
                                nval.push(s)
                            }
                            data[requirement.requiredInto] = nval
                        } else {
                            data[requirement.requiredInto] = requirement.data
                        }
                    }
                }
                await setTimeout(() => {
                    this.communication.emitEvent({
                        identifier: 'set-values',
                        form: data
                    })
                }, 100);
            } else if (msg.identifier === 'send-form') {
                const formGroup = msg.form as FormGroup
                const f = formGroup.value

                if (this.isEvaluation) {
                    this.reviewsService.saveForm(this.reviewId, this.formId, f)
                        .pipe(first())
                        .subscribe(data => {
                            //console.log(data)
                            if (this.toSave) this.saving = false
                        }, error => {
                            console.log(error)
                        })
                } else {
                    const formsFSOL08s = [12, 24, 25, 22, 23, 18, 212, 272, 273, 224, 225, 222, 274, 223, 275, 218, 276]
                    const formsFSOL07s = [30, 230]

                    //Validation forms 08's from review
                    if (formsFSOL08s.includes(this.formId)) {
                        //console.log("es 08-submit")
                        let isValid = true
                        if (
                            f.plan_organico_conformidad_no_conformidades_graves ||
                            f.plan_organico_conformidad_no_conformidades_menores_con_hallazgos ||
                            f.plan_organico_conformidad_no_conformidades_menores ||
                            f.plan_organico_conformidad_conformidad ||
                            (f.plan_organico_conformidad_otro && f.plan_organico_conformidad_otro_text !== '')
                        ) {
                            isValid = true
                        } else {
                            isValid = false
                        }

                        if (isValid) {
                            //Save form and sign review
                            this.reviewsService.saveForm(this.reviewId, this.formId, f)
                                .pipe(first())
                                .subscribe(data => {
                                    //console.log(data)
                                    if (this.toSave) this.saving = false

                                    this.reviewsService.signReview(this.reviewId, this.formId)
                                        .pipe(first())
                                        .subscribe(
                                            response => {
                                                this.saving = false
                                                this.modal.close(true)
                                                this.modal.dismiss()
                                            },
                                            error => {
                                                this.alertService.error(error);
                                            })

                                }, error => {
                                    this.alertService.error(error);
                                })

                        } else {
                            this.alertService.warn('Debe indicarse la conformidad de la evaluación del documento');
                        }
                    } else if(formsFSOL07s.includes(this.formId)) {
                        //console.log("es 07-submit")
                        let isValid = true

                        let listOfSubstances = f.lista_sustancias
                        for(var i in listOfSubstances) {
                            const substance = listOfSubstances[i]

                            //console.log(substance.verificacion_cor, substance.verificacion_lpo, substance.verificacion_no_permitido, substance.verificacion_opr)
                            if(substance.verificacion_cor || substance.verificacion_lpo || substance.verificacion_no_permitido || substance.verificacion_opr) {
                                //console.log("tiene al menos una seleccion")
                                continue
                            } else {
                                //console.log("no tiene ninguna seleccion")
                                isValid = false
                                break
                            }
                        }

                        if(isValid) {
                            //Save form and sign review
                            this.reviewsService.saveForm(this.reviewId, this.formId, f)
                                .pipe(first())
                                .subscribe(data => {
                                    //console.log(data)
                                    if (this.toSave) this.saving = false

                                    this.reviewsService.signReview(this.reviewId, this.formId)
                                        .pipe(first())
                                        .subscribe(
                                            response => {
                                                this.saving = false
                                                this.modal.close(true)
                                                this.modal.dismiss()
                                            },
                                            error => {
                                                this.alertService.error(error);
                                            })
                                }, error => {
                                    this.alertService.error(error);
                                })
                        } else {
                            this.alertService.warn('Debe indicarse la verificación para cada una de las sustancias');
                        }
                    } else {
                        //console.log("no es 08-submit ni 07-submit")
                        //Save form and sign review
                        this.reviewsService.saveForm(this.reviewId, this.formId, f)
                            .pipe(first())
                            .subscribe(data => {
                                //console.log(data)
                                if (this.toSave) this.saving = false
                                this.reviewsService.signReview(this.reviewId, this.formId)
                                    .pipe(first())
                                    .subscribe(
                                        response => {
                                            this.saving = false
                                            this.modal.close(true)
                                            this.modal.dismiss()
                                        },
                                        error => {
                                            this.alertService.error(error);
                                        })
                            }, error => {
                                this.alertService.error(error);
                            })
                    }
                }
            }
        })
    }

    getFilename(url: string) {
        if (url.constructor.name !== 'String') {
            return ''
        }
        const parts = url.split("/")
        const name = parts[parts.length - 1]
        return name
    }

    loadAttachments(submission: any) {
        // puede ser un array
        if (Array.isArray(submission)) {
            for (var i in submission) {
                const elem = submission[i]
                this.loadAttachments(elem)
            }
            // puede ser un string
        } else if (typeof submission === 'string') {
            if (submission.includes('http')) {
                this.attachments.push(submission)
            }
            // o puede ser un objeto
        } else if (typeof submission === 'object' && submission) {
            const keys = Object.keys(submission)
            for (var i in keys) {
                this.loadAttachments(submission[keys[i]])
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }

    async ngOnInit() {
        this.saving = false

        if (this.isContract) {
            this.title =  this.name === '' ? "Contrato" : this.name
            this.showForm = false
            this.showDoc = true
            this.showEval = false
            setTimeout(() => {
                this.url = this.urlContract
                this.docloading = false
            }, 3000);
        } else if (this.isResultReview) {
            this.showDoc = true
            this.showForm = false
            this.showEval = false
            this.showReview = false
            this.toSave = false
            var doc
            if (this.isResultReviewVersion === '1') {
                const s = new DocumentCreatorMTO_FRYD_03_v1()
                doc = await s.create_MTO_FRYD_03(this.dataResult, this.signatureUrl)
            } else if (this.isResultReviewVersion === "2") {
                const s = new DocumentCreatorMTO_FRYD_03_v2()
                doc = await s.create_MTO_FRYD_03(this.dataResult, this.signatureUrl)
            }

            if (doc) {
                /* const item = "resultado" + this.dataResult.recordId + this.dataResult.reviewerId
                const tmpURL = localStorage.getItem(item)

                if (tmpURL) {
                    this.getDocumentByURL(tmpURL, doc, item)
                } else {
                    this.buildDocument(doc, item)
                } */

                this.buildDocument(doc, "")
            }
        } else if (this.isFinding) {
            this.title = "MTO-FRYD-05"
            this.showForm = false
            this.showDoc = true
            this.showEval = false
            setTimeout(() => {
                this.url = this.urlFinding
                this.docloading = false
            }, 3000);
        } else if (this.isNC) {
            this.evaluationService.getForm(this.reviewId, this.formId)
                .pipe(first())
                .subscribe(
                    async response => {
                        //console.log("FORM")
                        //console.log(response)
                        this.title = response.form.title
                        this.submission = response.submission
                        //this.loadAttachments(this.submission)
                        this.requirements = response.requirements
                        this.response = response
                        var doc

                        switch (this.formId) {
                            case 68:
                            case 268:
                                this.showDoc = true
                                this.showForm = false

                                if (this.formId === 68) {
                                    const s = new DocumentCreatorMTO_FRYD_05_v1()
                                    doc = await s.create_MTO_FRYD_05(response, this.indexRFYD)
                                } else if (this.formId === 268) {
                                    const s = new DocumentCreatorMTO_FRYD_05_v2()
                                    doc = await s.create_MTO_FRYD_05(response, this.indexRFYD)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            default:
                                this.showDoc = false
                                this.showForm = true
                                this.docloading = false
                                if (this.submission !== null) {
                                    let lastForm = {}
                                    for (var i in this.submission) {
                                        const obj = this.submission[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break
                        }

                        if (doc) {
                            /* const item = "hallazgo" + this.formId + this.reviewId + this.indexRFYD
                            const tmpURL = localStorage.getItem(item)

                            if (tmpURL) {
                                this.getDocumentByURL(tmpURL, doc, item)
                            } else {
                                this.buildDocument(doc, item)
                            } */

                            this.buildDocument(doc, "")
                        }
                    },
                    error => {
                        this.alertService.error(error);
                    })

        } else if (this.formId === -1) {
            this.title = "MTO-FSOL-01-1"
            this.showForm = false
            this.showDoc = true
            const s = new DocumentCreatorMTO_FSOL_01_1_v2()
            const doc = await s.create_MTO_FSOL_01_1()

            if (doc) {
                /* const item = "listaDocumentos"
                const tmpURL = localStorage.getItem(item)

                if (tmpURL) {
                    this.getDocumentByURL(tmpURL, doc, item)
                } else {
                    this.buildDocument(doc, item)
                } */

                this.buildDocument(doc, "")
            }
        } else if (this.isEvaluation) {
            this.evaluationService.getForm(this.reviewId, this.formId, this.isAnInspectionDoc, this.inspectionId)
                .pipe(first())
                .subscribe(
                    async response => {
                        //console.log("FORM")
                        //console.log(response)
                        this.title = this.name
                        this.submission = response.submission
                        this.requirements = response.requirements
                        this.response = response

                        //Get files specifics of forms with id 68/268, else get files normally
                        if (this.formId === 68 || this.formId === 268) {
                            for (var i in this.submission[0].value) {
                                const element = this.submission[0].value[i]
                                this.loadAttachments(element.informe_documentos_url)
                                this.loadAttachments(element.files)
                            }
                        } else {
                            this.loadAttachments(this.submission)
                        }

                        var doc
                        switch (this.formId) {
                            case 0:
                            case 200:
                                this.showForm = false
                                this.showDoc = true
                                if (this.formId === 0) {
                                    const s = new DocumentCreatorMTO_FSOL_01_v1()
                                    doc = await s.create_MTO_FSOL_01(response, this.signatureUrl, this.signatureUrl2)
                                } else if (this.formId === 200) {
                                    const s = new DocumentCreatorMTO_FSOL_01_v2()
                                    doc = await s.create_MTO_FSOL_01(response, this.signatureUrl, this.signatureUrl2)
                                }
                                break

                            case 1:
                            case 201:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 1) {
                                    const s = new DocumentCreatorMTO_FSOL_02_v1()
                                    doc = await s.create_MTO_FSOL_02(response, this.signatureUrl)
                                } else if (this.formId === 201) {
                                    const s = new DocumentCreatorMTO_FSOL_02_1_v2()
                                    doc = await s.create_MTO_FSOL_02_1(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 279:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 279) {
                                    const s = new DocumentCreatorMTO_FSOL_02_2_v2()
                                    doc = await s.create_MTO_FSOL_02_2(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 2:
                            case 52:
                            case 202:
                            case 252:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 52 || this.formId === 2) {
                                    const s = new DocumentCreatorMTO_FSOL_04_1_v1()
                                    doc = await s.create_MTO_FSOL_04_1(response, this.signatureUrl)
                                } else if (this.formId === 252 || this.formId === 202) {
                                    const s = new DocumentCreatorMTO_FSOL_04_1_v2()
                                    doc = await s.create_MTO_FSOL_04_1(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 285:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 285) {
                                    const s = new DocumentCreatorMTO_FSOL_04_2_v2()
                                    doc = await s.create_MTO_FSOL_04_2(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 16:
                            case 57:
                            case 216:
                            case 257:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 57 || this.formId === 16) {
                                    const s = new DocumentCreatorMTO_FSOL_04_2_v1()
                                    doc = await s.create_MTO_FSOL_04_2(response, this.signatureUrl)
                                } else if (this.formId === 257 || this.formId === 216) {
                                    const s = new DocumentCreatorMTO_FSOL_04_3_v2()
                                    doc = await s.create_MTO_FSOL_04_3(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 3:
                            case 203:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 3) {
                                    const s = new DocumentCreatorMTO_FSOL_05_v1()
                                    doc = await s.create_MTO_FSOL_05(response)
                                } else if (this.formId === 203) {
                                    const s = new DocumentCreatorMTO_FSOL_05_1_v2()
                                    doc = await s.create_MTO_FSOL_05_1(response)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 271:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 271) {
                                    const s = new DocumentCreatorMTO_FSOL_05_3_v2()
                                    doc = await s.create_MTO_FSOL_05_3(response)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 4:
                            case 204:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 4) {
                                    const s = new DocumentCreatorMTO_FSOL_06_v1()
                                    doc = await s.create_MTO_FSOL_06(response, this.signatureUrl)
                                } else if (this.formId === 204) {
                                    const s = new DocumentCreatorMTO_FSOL_06_v2()
                                    doc = await s.create_MTO_FSOL_06(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 30:
                            case 230:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 30) {
                                    const s = new DocumentCreatorMTO_FSOL_07_v1()
                                    doc = await s.create_MTO_FSOL_07(response, this.signatureUrl, this.signatureUrl2)
                                } else if (this.formId === 230) {
                                    const s = new DocumentCreatorMTO_FSOL_07_v2()
                                    doc = await s.create_MTO_FSOL_07(response, this.signatureUrl, this.signatureUrl2)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 51:
                            case 251:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 51) {
                                    const s = new DocumentCreatorMTO_FSOL_08_1_INSPECTION_v1()
                                    doc = await s.create_MTO_FSOL_08_1(response, this.signatureUrl)
                                } else if (this.formId === 251) {
                                    const s = new DocumentCreatorMTO_FSOL_08_1_1_v2()
                                    doc = await s.create_MTO_FSOL_08_1_1(response, false, this.signatureUrl, this.signatureUrl2)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 286:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 286) {
                                    const s = new DocumentCreatorMTO_FSOL_08_1_2_v2()
                                    doc = await s.create_MTO_FSOL_08_1_2(response, false, this.signatureUrl, this.signatureUrl2)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 287:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 287) {
                                    const s = new DocumentCreatorMTO_FSOL_08_1_3_v2()
                                    doc = await s.create_MTO_FSOL_08_1_3(response, false, this.signatureUrl, this.signatureUrl2)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);
                                break

                            case 59:
                            case 259:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 59) {
                                    const s = new DocumentCreatorMTO_FSOL_08_4_INSPECTION_v1()
                                    doc = s.create_MTO_FSOL_08_4(response)
                                } else if (this.formId === 259) {
                                    const s = new DocumentCreatorMTO_FSOL_08_1_4_v2()
                                    doc = await s.create_MTO_FSOL_08_1_4(response, false, this.signatureUrl, this.signatureUrl2)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 61:
                            case 261:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 61) {
                                    const s = new DocumentCreatorMTO_FSOL_08_5_INSPECTION_v1()
                                    doc = s.create_MTO_FSOL_08_5(response)
                                } else if (this.formId === 261) {
                                    const s = new DocumentCreatorMTO_FSOL_08_1_5_v2()
                                    doc = await s.create_MTO_FSOL_08_1_5(response, false, this.signatureUrl, this.signatureUrl2)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 54:
                            case 254:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 54) {
                                    const s = new DocumentCreatorMTO_FSOL_08_2_INSPECTION_v1()
                                    doc = await s.create_MTO_FSOL_08_2(response, this.signatureUrl)
                                } else if (this.formId === 254) {
                                    const s = new DocumentCreatorMTO_FSOL_08_2_1_v2()
                                    doc = await s.create_MTO_FSOL_08_2_1(response, false, this.signatureUrl, this.signatureUrl2)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 63:
                            case 263:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 63) {
                                    const s = new DocumentCreatorMTO_FSOL_08_7_INSPECTION_v1()
                                    doc = await s.create_MTO_FSOL_08_7(response)
                                } else if (this.formId === 263) {
                                    const s = new DocumentCreatorMTO_FSOL_08_2_3_v2()
                                    doc = await s.create_MTO_FSOL_08_2_3(response, false, this.signatureUrl, this.signatureUrl2)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 289:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 289) {
                                    const s = new DocumentCreatorMTO_FSOL_08_2_4_v2()
                                    doc = await s.create_MTO_FSOL_08_2_4(response, false, this.signatureUrl, this.signatureUrl2)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 56:
                            case 256:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 56) {
                                    const s = new DocumentCreatorMTO_FSOL_08_3_INSPECTION_v1()
                                    doc = await s.create_MTO_FSOL_08_3(response, this.signatureUrl)
                                } else if (this.formId === 256) {
                                    const s = new DocumentCreatorMTO_FSOL_08_3_v2()
                                    doc = await s.create_MTO_FSOL_08_3(response, false, this.signatureUrl, this.signatureUrl2)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 290:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 290) {
                                    const s = new DocumentCreatorMTO_FSOL_08_4_v2()
                                    doc = await s.create_MTO_FSOL_08_4(response, false, this.signatureUrl, this.signatureUrl2)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 14:
                            case 214:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 14) {
                                    const s = new DocumentCreatorMTO_FSOL_09_v1()
                                    doc = await s.create_MTO_FSOL_09(response, this.signatureUrl)
                                } else if (this.formId === 214) {
                                    const s = new DocumentCreatorMTO_FSOL_09_v2()
                                    doc = await s.create_MTO_FSOL_09(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 9:
                            case 209:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 9) {
                                    const s = new DocumentCreatorMTO_FSOL_10_v1()
                                    doc = await s.create_MTO_FSOL_10(response, this.signatureUrl)
                                } else if (this.formId === 209) {
                                    const s = new DocumentCreatorMTO_FSOL_10_v2()
                                    doc = await s.create_MTO_FSOL_10(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 10:
                            case 210:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 10) {
                                    const s = new DocumentCreatorMTO_FSOL_11_v1()
                                    doc = await s.create_MTO_FSOL_11(response, this.signatureUrl)
                                } else if (this.formId === 210) {
                                    const s = new DocumentCreatorMTO_FSOL_11_v2()
                                    doc = await s.create_MTO_FSOL_11(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 220:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 220) {
                                    const s = new DocumentCreatorMTO_FSOL_12_v2()
                                    doc = await s.create_MTO_FSOL_12(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 221:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 220) {
                                    const s = new DocumentCreatorMTO_FSOL_13_v2()
                                    doc = await s.create_MTO_FSOL_13(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 11:
                            case 211:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 11) {
                                    const s = new DocumentCreatorMTO_FSOL_14_v1()
                                    doc = await s.create_MTO_FSOL_14(response, this.signatureUrl)
                                } else if (this.formId === 211) {
                                    const s = new DocumentCreatorMTO_FSOL_14_v2()
                                    doc = await s.create_MTO_FSOL_14(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 278:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 278) {
                                    const s = new DocumentCreatorMTO_FSOL_21_v2()
                                    doc = await s.create_MTO_FSOL_21(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 32:
                            case 232:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 32) {
                                    const s = new DocumentCreatorMTO_FSOL_23_v1()
                                    doc = await s.create_MTO_FSOL_23(response, this.signatureUrl, this.signatureUrl2)
                                } else if (this.formId === 232) {
                                    const s = new DocumentCreatorMTO_FSOL_23_v2()
                                    doc = await s.create_MTO_FSOL_23(response, this.signatureUrl, this.signatureUrl2)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);
                                break

                            case 17:
                            case 217:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 17) {
                                    const s = new DocumentCreatorMTO_FSOL_24_v1()
                                    doc = await s.create_MTO_FSOL_24(response, this.signatureUrl)
                                } else if (this.formId === 217) {
                                    const s = new DocumentCreatorMTO_FSOL_24_v2()
                                    doc = await s.create_MTO_FSOL_24(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 40:
                            case 240:
                                const signatureUrlResponsible = await this.users.getSignature(this.getValueSubmission(this.submission, "responsibleId")).toPromise().catch(error => console.log(error))
                                signatureUrlResponsible
                                    ? signatureUrlResponsible.success
                                        ? this.signatureUrl2 = signatureUrlResponsible.file
                                        : ''
                                    : ''

                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 40) {
                                    const s = new DocumentCreatorMTO_FINS_01_v1()
                                    doc = await s.create_MTO_FINS_01(response, this.signatureUrl2, this.signatureUrl)
                                } else if (this.formId === 240) {
                                    const s = new DocumentCreatorMTO_FINS_01_v2()
                                    doc = await s.create_MTO_FINS_01(response, this.signatureUrl2, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 50:
                            case 250:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 50) {
                                    const s = new DocumentCreatorMTO_FINS_02_v1()
                                    doc = await s.create_MTO_FINS_02(response, this.signatureUrl2)
                                } else if (this.formId === 250) {
                                    const s = new DocumentCreatorMTO_FINS_02_v2()
                                    doc = await s.create_MTO_FINS_02(response, this.signatureUrl2)
                                }

                                break

                            case 53:
                            case 253:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 53) {
                                    const s = new DocumentCreatorMTO_FINS_03_1_v1()
                                    doc = s.create_MTO_FINS_03_1(response)
                                } else if (this.formId === 253) {
                                    const s = new DocumentCreatorMTO_FINS_03_1_1_v2()
                                    doc = s.create_MTO_FINS_03_1_1(response, this.requirements[1].data)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 280:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 280) {
                                    const s = new DocumentCreatorMTO_FINS_03_1_2_v2()
                                    doc = s.create_MTO_FINS_03_1_2(response, this.requirements[1].data)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 281:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 281) {
                                    const s = new DocumentCreatorMTO_FINS_03_1_3_v2()
                                    doc = s.create_MTO_FINS_03_1_3(response, this.requirements[1].data)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 60:
                            case 260:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 60) {
                                    const s = new DocumentCreatorMTO_FINS_03_4_v1()
                                    doc = s.create_MTO_FINS_03_4(response)
                                } else if (this.formId === 260) {
                                    const s = new DocumentCreatorMTO_FINS_03_1_4_v2()
                                    doc = s.create_MTO_FINS_03_1_4(response, this.requirements[1].data)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 62:
                            case 262:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 62) {
                                    const s = new DocumentCreatorMTO_FINS_03_5_v1()
                                    doc = s.create_MTO_FINS_03_5(response)
                                } else if (this.formId === 262) {
                                    const s = new DocumentCreatorMTO_FINS_03_1_5_v2()
                                    doc = s.create_MTO_FINS_03_1_5(response, this.requirements[1].data)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 55:
                            case 255:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 55) {
                                    const s = new DocumentCreatorMTO_FINS_03_2_v1()
                                    doc = s.create_MTO_FINS_03_2(response)
                                } else if (this.formId === 255) {
                                    const s = new DocumentCreatorMTO_FINS_03_2_1_v2()
                                    doc = s.create_MTO_FINS_03_2_1(response, this.requirements[3].data)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 64:
                            case 264:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 64) {
                                    const s = new DocumentCreatorMTO_FINS_03_7_v1()
                                    doc = s.create_MTO_FINS_03_7(response)
                                } else if (this.formId === 264) {
                                    const s = new DocumentCreatorMTO_FINS_03_2_3_v2()
                                    doc = s.create_MTO_FINS_03_2_3(response, this.requirements[1].data)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 283:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 283) {
                                    const s = new DocumentCreatorMTO_FINS_03_2_4_v2()
                                    doc = s.create_MTO_FINS_03_2_4(response, this.requirements[2].data)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 58:
                            case 258:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 58) {
                                    const s = new DocumentCreatorMTO_FINS_03_3_v1()
                                    doc = s.create_MTO_FINS_03_3(response)
                                } else if (this.formId === 258) {
                                    const s = new DocumentCreatorMTO_FINS_03_3_v2()
                                    doc = s.create_MTO_FINS_03_3(response, this.requirements[2].data)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 284:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 284) {
                                    const s = new DocumentCreatorMTO_FINS_03_4_v2()
                                    doc = s.create_MTO_FINS_03_4(response, this.requirements[2].data)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 65:
                            case 265:
                                this.showDoc = this.isChecklist ? true : false
                                this.showForm = !this.isChecklist ? true : false

                                if(this.isChecklist) {
                                    if (this.formId === 65) {
                                        const s = new DocumentCreatorMTO_FINS_04_v1()
                                        doc = await s.create_MTO_FINS_04(response, this.signatureUrl, this.signatureUrl2)
                                    } else if (this.formId === 265) {
                                        const s = new DocumentCreatorMTO_FINS_04_v2()
                                        doc = await s.create_MTO_FINS_04(response, this.signatureUrl, this.signatureUrl2)
                                    }

                                    if (this.response.special !== null) {
                                        let lastForm = {}
                                        for (var i in this.response.special) {
                                            const obj = this.response.special[i]
                                            lastForm[obj.name] = obj.value
                                        }
                                        setTimeout(() => {
                                            this.communication.emitEvent({
                                                identifier: 'set-values',
                                                form: lastForm
                                            })
                                        }, 100);
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'submit'
                                        })
                                    }, 100);
                                } else {
                                    this.docloading = false
                                    if (this.submission !== null) {
                                        let lastForm = {}
                                        for (var i in this.submission) {
                                            const obj = this.submission[i]
                                            lastForm[obj.name] = obj.value
                                        }
                                        setTimeout(() => {
                                            this.communication.emitEvent({
                                                identifier: 'set-values',
                                                form: lastForm
                                            })
                                        }, 100);
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'submit'
                                        })
                                    }, 100);
                                }

                                break

                            case 68:
                            case 268:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 68) {
                                    const s = new DocumentCreatorMTO_FINS_04_v1()
                                    doc = await s.create_MTO_FINS_04(response, this.signatureUrl, this.signatureUrl2)
                                } else if (this.formId === 268) {
                                    const s = new DocumentCreatorMTO_FINS_04_v2()
                                    doc = await s.create_MTO_FINS_04(response, this.signatureUrl, this.signatureUrl2)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 66:
                            case 266:
                            case 91:
                            case 291:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 66 || this.formId === 91) {
                                    const s = new DocumentCreatorMTO_FINS_06_v1()
                                    doc = await s.create_MTO_FINS_06(response, this.signatureUrl, this.signatureUrl2)
                                } else if (this.formId === 266 || this.formId === 291) {
                                    const s = new DocumentCreatorMTO_FINS_06_v2()
                                    doc = await s.create_MTO_FINS_06(response, this.signatureUrl, this.signatureUrl2)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 293:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 293) {
                                    const s = new DocumentCreatorMTO_FRYD_01()
                                    doc = await s.create_MTO_FRYD_01(this.response)
                                }

                                break

                            default:
                                this.showDoc = false
                                this.showForm = true
                                this.docloading = false
                                if (this.submission !== null) {
                                    let lastForm = {}
                                    for (var i in this.submission) {
                                        const obj = this.submission[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break
                        }

                        if (doc) {
                            /* const item = "evaluation" + this.formId + this.reviewId + this.inspectionId
                            const tmpURL = localStorage.getItem(item)

                            if (tmpURL) {
                                this.getDocumentByURL(tmpURL, doc, item)
                            } else {
                                this.buildDocument(doc, item)
                            } */

                            this.buildDocument(doc, "")
                        }
                    },
                    error => {
                        this.alertService.error(error);
                    })
        } else if (this.isInspection) {
            this.inspectionService.getForm(this.reviewId, this.formId)
                .pipe(first())
                .subscribe(
                    async response => {
                        //console.log("FORM")
                        //console.log(response)
                        this.title = response.data.form.title
                        this.submission = response.data.submission
                        this.loadAttachments(this.submission)
                        this.requirements = response.data.requirements
                        this.response = response.data
                        var doc

                        switch (this.formId) {
                            case 40:
                            case 240:
                                const signatureUrlResponsible = await this.users.getSignature(this.getValueSubmission(this.submission, "responsibleId")).toPromise().catch(error => console.log(error))
                                signatureUrlResponsible
                                    ? signatureUrlResponsible.success
                                        ? this.signatureUrl2 = signatureUrlResponsible.file
                                        : ''
                                    : ''

                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 40) {
                                    const s = new DocumentCreatorMTO_FINS_01_v1()
                                    doc = await s.create_MTO_FINS_01(this.response, this.signatureUrl2, this.signatureUrl)
                                } else if (this.formId == 240) {
                                    const s = new DocumentCreatorMTO_FINS_01_v2()
                                    doc = await s.create_MTO_FINS_01(this.response, this.signatureUrl2, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 50:
                            case 250:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 50) {
                                    const s = new DocumentCreatorMTO_FINS_02_v1()
                                    doc = await s.create_MTO_FINS_02(this.response, this.signatureUrl)
                                } else if (this.formId === 250) {
                                    const s = new DocumentCreatorMTO_FINS_02_v2()
                                    doc = await s.create_MTO_FINS_02(this.response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            default:
                                this.showDoc = false
                                this.showForm = true
                                this.docloading = false
                                if (this.submission !== null) {
                                    let lastForm = {}
                                    for (var i in this.submission) {
                                        const obj = this.submission[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break
                        }

                        if (doc) {
                            /* const item = "inspection" + this.formId + this.reviewId
                            const tmpURL = localStorage.getItem(item)

                            if (tmpURL) {
                                this.getDocumentByURL(tmpURL, doc, item)
                            } else {
                                this.buildDocument(doc, item)
                            } */

                            this.buildDocument(doc, "")
                        }
                    },
                    error => {
                        this.alertService.error(error);
                    })
        } else if (this.isApplication) {
            this.requestsService.getFormPreview(this.reviewId, this.formId)
                .pipe(first())
                .subscribe(

                    async response => {
                        //console.log("FORM")
                        //console.log(response)
                        this.title = response.form.title
                        this.submission = response.submission
                        this.loadAttachments(this.submission)
                        this.requirements = response.requirements
                        this.response = response
                        var doc
                        switch (this.formId) {
                            case 0:
                            case 200:
                                this.showForm = false
                                this.showDoc = true
                                if (this.formId === 0) {
                                    const s = new DocumentCreatorMTO_FSOL_01_v1()
                                    doc = await s.create_MTO_FSOL_01(response, this.signatureUrl)
                                } else if (this.formId === 200) {
                                    const s = new DocumentCreatorMTO_FSOL_01_v2()
                                    doc = await s.create_MTO_FSOL_01(response, this.signatureUrl)
                                }

                                break

                            case 1:
                            case 201:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 1) {
                                    const s = new DocumentCreatorMTO_FSOL_02_v1()
                                    doc = await s.create_MTO_FSOL_02(response, this.signatureUrl)
                                } else if (this.formId === 201) {
                                    const s = new DocumentCreatorMTO_FSOL_02_1_v2()
                                    doc = await s.create_MTO_FSOL_02_1(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 279:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 279) {
                                    const s = new DocumentCreatorMTO_FSOL_02_2_v2()
                                    doc = await s.create_MTO_FSOL_02_2(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 2:
                            case 202:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 2) {
                                    const s = new DocumentCreatorMTO_FSOL_04_1_v1()
                                    doc = await s.create_MTO_FSOL_04_1(response, this.signatureUrl)
                                } else if (this.formId === 202) {
                                    const s = new DocumentCreatorMTO_FSOL_04_1_v2()
                                    doc = await s.create_MTO_FSOL_04_1(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 270:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 270) {
                                    const s = new DocumentCreatorMTO_FSOL_04_2_v2()
                                    doc = await s.create_MTO_FSOL_04_2(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 16:
                            case 216:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 16) {
                                    const s = new DocumentCreatorMTO_FSOL_04_2_v1()
                                    doc = await s.create_MTO_FSOL_04_2(response, this.signatureUrl)
                                } else if (this.formId === 216) {
                                    const s = new DocumentCreatorMTO_FSOL_04_3_v2()
                                    doc = await s.create_MTO_FSOL_04_3(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 3:
                            case 203:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 3) {
                                    const s = new DocumentCreatorMTO_FSOL_05_v1()
                                    doc = await s.create_MTO_FSOL_05(response)
                                } else if (this.formId === 203) {
                                    const s = new DocumentCreatorMTO_FSOL_05_1_v2()
                                    doc = await s.create_MTO_FSOL_05_1(response)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 271:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 271) {
                                    const s = new DocumentCreatorMTO_FSOL_05_3_v2()
                                    doc = await s.create_MTO_FSOL_05_3(response)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 4:
                            case 204:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 4) {
                                    const s = new DocumentCreatorMTO_FSOL_06_v1()
                                    doc = await s.create_MTO_FSOL_06(response, this.signatureUrl)
                                } else if (this.formId === 204) {
                                    const s = new DocumentCreatorMTO_FSOL_06_v2()
                                    doc = await s.create_MTO_FSOL_06(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 12:
                            case 212:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 12) {
                                    const s = new DocumentCreatorMTO_FSOL_08_1_v1()
                                    doc = await s.create_MTO_FSOL_08_1(response, this.signatureUrl)
                                } else if (this.formId === 212) {
                                    const s = new DocumentCreatorMTO_FSOL_08_1_1_v2()
                                    doc = await s.create_MTO_FSOL_08_1_1(response, true, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 272:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 272) {
                                    const s = new DocumentCreatorMTO_FSOL_08_1_2_v2()
                                    doc = await s.create_MTO_FSOL_08_1_2(response, true, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 273:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 273) {
                                    const s = new DocumentCreatorMTO_FSOL_08_1_3_v2()
                                    doc = await s.create_MTO_FSOL_08_1_3(response, true, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 24:
                            case 224:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 24) {
                                    const s = new DocumentCreatorMTO_FSOL_08_4_v1()
                                    doc = s.create_MTO_FSOL_08_4(response)
                                } else if (this.formId === 224) {
                                    const s = new DocumentCreatorMTO_FSOL_08_1_4_v2()
                                    doc = await s.create_MTO_FSOL_08_1_4(response, true, this.signatureUrl)
                                }
                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 25:
                            case 225:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 25) {
                                    const s = new DocumentCreatorMTO_FSOL_08_5_v1()
                                    doc = s.create_MTO_FSOL_08_5(response)
                                } else if (this.formId === 225) {
                                    const s = new DocumentCreatorMTO_FSOL_08_1_5_v2()
                                    doc = await s.create_MTO_FSOL_08_1_5(response, true, this.signatureUrl)
                                }
                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 22:
                            case 222:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 22) {
                                    const s = new DocumentCreatorMTO_FSOL_08_2_v1()
                                    doc = await s.create_MTO_FSOL_08_2(response, this.signatureUrl)
                                } else if (this.formId === 222) {
                                    const s = new DocumentCreatorMTO_FSOL_08_2_1_v2()
                                    doc = await s.create_MTO_FSOL_08_2_1(response, true, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 274:
                                //Sin fomulario
                                break

                            case 23:
                            case 223:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 23) {
                                    const s = new DocumentCreatorMTO_FSOL_08_7_v1()
                                    doc = s.create_MTO_FSOL_08_7(response)
                                } else if (this.formId === 223) {
                                    const s = new DocumentCreatorMTO_FSOL_08_2_3_v2()
                                    doc = await s.create_MTO_FSOL_08_2_3(response, true, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 275:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 275) {
                                    const s = new DocumentCreatorMTO_FSOL_08_2_4_v2()
                                    doc = await s.create_MTO_FSOL_08_2_4(response, true, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 18:
                            case 218:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 18) {
                                    const s = new DocumentCreatorMTO_FSOL_08_3_v1()
                                    doc = await s.create_MTO_FSOL_08_3(response, this.signatureUrl)
                                } else if (this.formId === 218) {
                                    const s = new DocumentCreatorMTO_FSOL_08_3_v2()
                                    doc = await s.create_MTO_FSOL_08_3(response, true, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 276:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 276) {
                                    const s = new DocumentCreatorMTO_FSOL_08_4_v2()
                                    doc = await s.create_MTO_FSOL_08_4(response, true, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 14:
                            case 214:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 14) {
                                    const s = new DocumentCreatorMTO_FSOL_09_v1()
                                    doc = await s.create_MTO_FSOL_09(response, this.signatureUrl)
                                } else if (this.formId === 214) {
                                    const s = new DocumentCreatorMTO_FSOL_09_v2()
                                    doc = await s.create_MTO_FSOL_09(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 9:
                            case 209:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 9) {
                                    const s = new DocumentCreatorMTO_FSOL_10_v1()
                                    doc = await s.create_MTO_FSOL_10(response, this.signatureUrl)
                                } else if (this.formId === 209) {
                                    const s = new DocumentCreatorMTO_FSOL_10_v2()
                                    doc = await s.create_MTO_FSOL_10(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 10:
                            case 210:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 10) {
                                    const s = new DocumentCreatorMTO_FSOL_11_v1()
                                    doc = await s.create_MTO_FSOL_11(response, this.signatureUrl)
                                } else if (this.formId === 210) {
                                    const s = new DocumentCreatorMTO_FSOL_11_v2()
                                    doc = await s.create_MTO_FSOL_11(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 20:
                            case 220:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 20) {
                                    /* const s = new DocumentCreatorMTO_FSOL_12_v1()
                                    doc = await s.create_MTO_FSOL_12(response, this.signatureUrl) */
                                } else if (this.formId === 220) {
                                    const s = new DocumentCreatorMTO_FSOL_12_v2()
                                    doc = await s.create_MTO_FSOL_12(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 21:
                            case 221:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 21) {
                                    /* const s = new DocumentCreatorMTO_FSOL_13_v1()
                                    doc = await s.create_MTO_FSOL_13(response, this.signatureUrl) */
                                } else if (this.formId === 221) {
                                    const s = new DocumentCreatorMTO_FSOL_13_v2()
                                    doc = await s.create_MTO_FSOL_13(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 11:
                            case 211:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 11) {
                                    const s = new DocumentCreatorMTO_FSOL_14_v1()
                                    doc = await s.create_MTO_FSOL_14(response, this.signatureUrl)
                                } else if (this.formId === 211) {
                                    const s = new DocumentCreatorMTO_FSOL_14_v2()
                                    doc = await s.create_MTO_FSOL_14(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 278:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 278) {
                                    const s = new DocumentCreatorMTO_FSOL_21_v2()
                                    doc = await s.create_MTO_FSOL_21(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 17:
                            case 217:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 17) {
                                    const s = new DocumentCreatorMTO_FSOL_24_v1()
                                    doc = await s.create_MTO_FSOL_24(response, this.signatureUrl)
                                } else if (this.formId === 217) {
                                    const s = new DocumentCreatorMTO_FSOL_24_v2()
                                    doc = await s.create_MTO_FSOL_24(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 13:
                            case 213:
                                this.showDoc = true
                                this.showForm = false

                                if (this.formId === 13) {
                                    const s = new DocumentCreatorMTO_FSOL_07_v1()
                                    doc = await s.create_MTO_FSOL_07(response, this.signatureUrl)
                                } else if (this.formId === 213) {
                                    const s = new DocumentCreatorMTO_FSOL_07_v2()
                                    doc = await s.create_MTO_FSOL_07(response, this.signatureUrl)
                                }

                                if (this.submission !== null) {
                                    let lastForm = {}
                                    for (var i in this.submission) {
                                        const obj = this.submission[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                } else {
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'submit'
                                        })
                                    }, 100);
                                }

                                break

                            case 15:
                            case 215:
                                this.data = response
                                this.showDoc = true
                                this.showForm = false

                                if (this.formId === 15) {
                                    const s = new DocumentCreatorMTO_FSOL_23_v1()
                                    doc = await s.create_MTO_FSOL_23(response, this.signatureUrl)
                                } else if (this.formId === 215) {
                                    const s = new DocumentCreatorMTO_FSOL_23_v2()
                                    doc = await s.create_MTO_FSOL_23(response, this.signatureUrl)
                                }

                                if (this.data.requirements !== null) {
                                    let lastForm = {}
                                    let listRequeriments = this.getValueListRequirements(this.data.requirements, "lista_proveedores")
                                    for (var i in listRequeriments) {
                                        const obj = listRequeriments[i]
                                        if (this.formId === 15) {
                                            obj["certificacion_lpo_fecha_vigencia"] = obj["certificacion_lpo_fecha_vigencia"] !== ''
                                                ? "LPO " + obj["certificacion_lpo_fecha_vigencia"] + (obj["certificacion_lpo_en_vigencia"]
                                                    ? " Vigencia"
                                                    : obj["certificacion_lpo_en_aniversario"]
                                                        ? " Aniversario"
                                                        : "")
                                                : ''

                                            obj["certificacion_opr_fecha_vigencia"] = obj["certificacion_opr_fecha_vigencia"] !== ''
                                                ? "OPR " + obj["certificacion_opr_fecha_vigencia"] + (obj["certificacion_opr_en_vigencia"]
                                                    ? " Vigencia"
                                                    : obj["certificacion_opr_en_aniversario"]
                                                        ? " Aniversario"
                                                        : "")
                                                : ''

                                            obj["certificacion_nop_fecha_vigencia"] = obj["certificacion_nop_fecha_vigencia"] !== ''
                                                ? "NOP " + obj["certificacion_nop_fecha_vigencia"] + (obj["certificacion_nop_en_vigencia"]
                                                    ? " Vigencia"
                                                    : obj["certificacion_nop_en_aniversario"]
                                                        ? " Aniversario"
                                                        : "")
                                                : ''

                                            obj["certificacion_otro_fecha_vigencia"] = obj["certificacion_otro_fecha_vigencia"] !== ''
                                                ? obj["certificacion_otro_text"] + " " + obj["certificacion_otro_fecha_vigencia"] + (obj["certificacion_otro_en_vigencia"]
                                                    ? " Vigencia"
                                                    : obj["certificacion_otro_en_aniversario"]
                                                        ? " Aniversario"
                                                        : "")
                                                : ''
                                        } else if (this.formId === 215) {
                                            obj["certificacion_lpo_fecha_vigencia"] = obj["certificacion_lpo_fecha_vigencia"] !== ''
                                                ? "LPO " + obj["certificacion_lpo_fecha_vigencia"] + (obj["certificacion_lpo_en_vigencia"]
                                                    ? " Vigencia"
                                                    : obj["certificacion_lpo_en_aniversario"]
                                                        ? " Aniversario"
                                                        : "")
                                                : ''

                                            obj["certificacion_cor_fecha_vigencia"] = obj["certificacion_cor_fecha_vigencia"] !== ''
                                                ? "COR " + obj["certificacion_cor_fecha_vigencia"] + (obj["certificacion_cor_en_vigencia"]
                                                    ? " Vigencia"
                                                    : obj["certificacion_cor_en_aniversario"]
                                                        ? " Aniversario"
                                                        : "")
                                                : ''

                                            obj["certificacion_nop_fecha_vigencia"] = obj["certificacion_nop_fecha_vigencia"] !== ''
                                                ? "NOP " + obj["certificacion_nop_fecha_vigencia"] + (obj["certificacion_nop_en_vigencia"]
                                                    ? " Vigencia"
                                                    : obj["certificacion_nop_en_aniversario"]
                                                        ? " Aniversario"
                                                        : "")
                                                : ''

                                            obj["certificacion_otro_fecha_vigencia"] = obj["certificacion_otro_fecha_vigencia"] !== ''
                                                ? obj["certificacion_otro_text"] + " " + obj["certificacion_otro_fecha_vigencia"] + (obj["certificacion_otro_en_vigencia"]
                                                    ? " Vigencia"
                                                    : obj["certificacion_otro_en_aniversario"]
                                                        ? " Aniversario"
                                                        : "")
                                                : ''
                                        }

                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);
                                break

                            default:
                                this.showDoc = false
                                this.showForm = true
                                if (this.submission !== null) {
                                    let lastForm = {}
                                    for (var i in this.submission) {
                                        const obj = this.submission[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break
                        }

                        if (doc) {
                            /* const item = "application" + this.formId + this.reviewId
                            const tmpURL = localStorage.getItem(item)

                            if (tmpURL) {
                                this.getDocumentByURL(tmpURL, doc, item)
                            } else {
                                this.buildDocument(doc, item)
                            } */

                            this.buildDocument(doc, "")
                        }
                    },
                    error => {
                        this.alertService.error(error);
                    })
        } else {
            this.reviewsService.getForm(this.reviewId, this.formId)
                .pipe(first())
                .subscribe(
                    async response => {
                        //console.log("FORM")
                        //console.log(response)
                        this.title = response.form.title
                        this.submission = response.submission
                        this.loadAttachments(this.submission)
                        this.requirements = response.requirements
                        this.response = response
                        var doc
                        switch (this.formId) {
                            case 0:
                            case 200:
                                this.showForm = false
                                this.showDoc = true
                                if (this.formId === 0) {
                                    const s = new DocumentCreatorMTO_FSOL_01_v1()
                                    doc = await s.create_MTO_FSOL_01(response, this.signatureUrl)
                                } else if (this.formId === 200) {
                                    const s = new DocumentCreatorMTO_FSOL_01_v2()
                                    doc = await s.create_MTO_FSOL_01(response, this.signatureUrl)
                                }

                                break

                            case 1:
                            case 201:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 1) {
                                    const s = new DocumentCreatorMTO_FSOL_02_v1()
                                    doc = await s.create_MTO_FSOL_02(response, this.signatureUrl)
                                } else if (this.formId === 201) {
                                    const s = new DocumentCreatorMTO_FSOL_02_1_v2()
                                    doc = await s.create_MTO_FSOL_02_1(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 279:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 279) {
                                    const s = new DocumentCreatorMTO_FSOL_02_2_v2()
                                    doc = await s.create_MTO_FSOL_02_2(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 2:
                            case 202:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 2) {
                                    const s = new DocumentCreatorMTO_FSOL_04_1_v1()
                                    doc = await s.create_MTO_FSOL_04_1(response, this.signatureUrl)
                                } else if (this.formId === 202) {
                                    const s = new DocumentCreatorMTO_FSOL_04_1_v2()
                                    doc = await s.create_MTO_FSOL_04_1(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 270:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 270) {
                                    const s = new DocumentCreatorMTO_FSOL_04_2_v2()
                                    doc = await s.create_MTO_FSOL_04_2(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 16:
                            case 216:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 16) {
                                    const s = new DocumentCreatorMTO_FSOL_04_2_v1()
                                    doc = await s.create_MTO_FSOL_04_2(response, this.signatureUrl)
                                } else if (this.formId === 216) {
                                    const s = new DocumentCreatorMTO_FSOL_04_3_v2()
                                    doc = await s.create_MTO_FSOL_04_3(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 3:
                            case 203:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 3) {
                                    const s = new DocumentCreatorMTO_FSOL_05_v1()
                                    doc = await s.create_MTO_FSOL_05(response)
                                } else if (this.formId === 203) {
                                    const s = new DocumentCreatorMTO_FSOL_05_1_v2()
                                    doc = await s.create_MTO_FSOL_05_1(response)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 271:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 271) {
                                    const s = new DocumentCreatorMTO_FSOL_05_3_v2()
                                    doc = await s.create_MTO_FSOL_05_3(response)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 4:
                            case 204:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 4) {
                                    const s = new DocumentCreatorMTO_FSOL_06_v1()
                                    doc = await s.create_MTO_FSOL_06(response, this.signatureUrl)
                                } else if (this.formId === 204) {
                                    const s = new DocumentCreatorMTO_FSOL_06_v2()
                                    doc = await s.create_MTO_FSOL_06(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 12:
                            case 212:
                                this.showDoc = true
                                this.showForm = true
                                if (this.formId === 12) {
                                    const s = new DocumentCreatorMTO_FSOL_08_1_v1()
                                    doc = await s.create_MTO_FSOL_08_1(response, this.signatureUrl)
                                } else if (this.formId === 212) {
                                    const s = new DocumentCreatorMTO_FSOL_08_1_1_v2()
                                    doc = await s.create_MTO_FSOL_08_1_1(response, true, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 272:
                                this.showDoc = true
                                this.showForm = true
                                if (this.formId === 272) {
                                    const s = new DocumentCreatorMTO_FSOL_08_1_2_v2()
                                    doc = await s.create_MTO_FSOL_08_1_2(response, true, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 273:
                                this.showDoc = true
                                this.showForm = true
                                if (this.formId === 273) {
                                    const s = new DocumentCreatorMTO_FSOL_08_1_3_v2()
                                    doc = await s.create_MTO_FSOL_08_1_3(response, true, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 24:
                            case 224:
                                this.showDoc = true
                                this.showForm = true
                                if (this.formId === 24) {
                                    const s = new DocumentCreatorMTO_FSOL_08_4_v1()
                                    doc = s.create_MTO_FSOL_08_4(response)
                                } else if (this.formId === 224) {
                                    const s = new DocumentCreatorMTO_FSOL_08_1_4_v2()
                                    doc = await s.create_MTO_FSOL_08_1_4(response, true, this.signatureUrl)
                                }
                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 25:
                            case 225:
                                this.showDoc = true
                                this.showForm = true
                                if (this.formId === 25) {
                                    const s = new DocumentCreatorMTO_FSOL_08_5_v1()
                                    doc = s.create_MTO_FSOL_08_5(response)
                                } else if (this.formId === 225) {
                                    const s = new DocumentCreatorMTO_FSOL_08_1_5_v2()
                                    doc = await s.create_MTO_FSOL_08_1_5(response, true, this.signatureUrl)
                                }
                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 22:
                            case 222:
                                this.showDoc = true
                                this.showForm = true
                                if (this.formId === 22) {
                                    const s = new DocumentCreatorMTO_FSOL_08_2_v1()
                                    doc = await s.create_MTO_FSOL_08_2(response, this.signatureUrl)
                                } else if (this.formId === 222) {
                                    const s = new DocumentCreatorMTO_FSOL_08_2_1_v2()
                                    doc = await s.create_MTO_FSOL_08_2_1(response, true, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 274:
                                //Sin fomulario
                                break

                            case 23:
                            case 223:
                                this.showDoc = true
                                this.showForm = true
                                if (this.formId === 23) {
                                    const s = new DocumentCreatorMTO_FSOL_08_7_v1()
                                    doc = s.create_MTO_FSOL_08_7(response)
                                } else if (this.formId === 223) {
                                    const s = new DocumentCreatorMTO_FSOL_08_2_3_v2()
                                    doc = await s.create_MTO_FSOL_08_2_3(response, true, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 275:
                                this.showDoc = true
                                this.showForm = true
                                if (this.formId === 275) {
                                    const s = new DocumentCreatorMTO_FSOL_08_2_4_v2()
                                    doc = await s.create_MTO_FSOL_08_2_4(response, true, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 18:
                            case 218:
                                this.showDoc = true
                                this.showForm = true
                                if (this.formId === 18) {
                                    const s = new DocumentCreatorMTO_FSOL_08_3_v1()
                                    doc = await s.create_MTO_FSOL_08_3(response, this.signatureUrl)
                                } else if (this.formId === 218) {
                                    const s = new DocumentCreatorMTO_FSOL_08_3_v2()
                                    doc = await s.create_MTO_FSOL_08_3(response, true, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 276:
                                this.showDoc = true
                                this.showForm = true
                                if (this.formId === 276) {
                                    const s = new DocumentCreatorMTO_FSOL_08_4_v2()
                                    doc = await s.create_MTO_FSOL_08_4(response, true, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 14:
                            case 214:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 14) {
                                    const s = new DocumentCreatorMTO_FSOL_09_v1()
                                    doc = await s.create_MTO_FSOL_09(response, this.signatureUrl)
                                } else if (this.formId === 214) {
                                    const s = new DocumentCreatorMTO_FSOL_09_v2()
                                    doc = await s.create_MTO_FSOL_09(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 9:
                            case 209:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 9) {
                                    const s = new DocumentCreatorMTO_FSOL_10_v1()
                                    doc = await s.create_MTO_FSOL_10(response, this.signatureUrl)
                                } else if (this.formId === 209) {
                                    const s = new DocumentCreatorMTO_FSOL_10_v2()
                                    doc = await s.create_MTO_FSOL_10(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 10:
                            case 210:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 10) {
                                    const s = new DocumentCreatorMTO_FSOL_11_v1()
                                    doc = await s.create_MTO_FSOL_11(response, this.signatureUrl)
                                } else if (this.formId === 210) {
                                    const s = new DocumentCreatorMTO_FSOL_11_v2()
                                    doc = await s.create_MTO_FSOL_11(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 20:
                            case 220:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 20) {
                                    /* const s = new DocumentCreatorMTO_FSOL_12_v1()
                                    doc = await s.create_MTO_FSOL_12(response, this.signatureUrl) */
                                } else if (this.formId === 220) {
                                    const s = new DocumentCreatorMTO_FSOL_12_v2()
                                    doc = await s.create_MTO_FSOL_12(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 21:
                            case 221:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 21) {
                                    /* const s = new DocumentCreatorMTO_FSOL_13_v1()
                                    doc = await s.create_MTO_FSOL_13(response, this.signatureUrl) */
                                } else if (this.formId === 221) {
                                    const s = new DocumentCreatorMTO_FSOL_13_v2()
                                    doc = await s.create_MTO_FSOL_13(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 11:
                            case 211:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 11) {
                                    const s = new DocumentCreatorMTO_FSOL_14_v1()
                                    doc = await s.create_MTO_FSOL_14(response, this.signatureUrl)
                                } else if (this.formId === 211) {
                                    const s = new DocumentCreatorMTO_FSOL_14_v2()
                                    doc = await s.create_MTO_FSOL_14(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 278:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 278) {
                                    const s = new DocumentCreatorMTO_FSOL_21_v2()
                                    doc = await s.create_MTO_FSOL_21(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 17:
                            case 217:
                                this.showDoc = true
                                this.showForm = false
                                if (this.formId === 17) {
                                    const s = new DocumentCreatorMTO_FSOL_24_v1()
                                    doc = await s.create_MTO_FSOL_24(response, this.signatureUrl)
                                } else if (this.formId === 217) {
                                    const s = new DocumentCreatorMTO_FSOL_24_v2()
                                    doc = await s.create_MTO_FSOL_24(response, this.signatureUrl)
                                }

                                if (this.response.special !== null) {
                                    let lastForm = {}
                                    for (var i in this.response.special) {
                                        const obj = this.response.special[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break

                            case 30:
                            case 230:
                                this.showDoc = false
                                this.showForm = true
                                this.docloading = false
                                if (this.submission !== null) {
                                    let lastForm = {}
                                    for (var i in this.submission) {
                                        const obj = this.submission[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                } else {
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'submit'
                                        })
                                    }, 100);
                                }

                                break

                            case 32:
                            case 232:
                                this.data = response
                                this.showDoc = false
                                this.docloading = false
                                this.showForm = true
                                if (this.data.requirements !== null) {
                                    let lastForm = {}
                                    let listRequeriments = this.getValueListRequirements(this.data.requirements, "lista_proveedores")
                                    for (var i in listRequeriments) {
                                        const obj = listRequeriments[i]
                                        if (this.formId === 32) {
                                            obj["certificacion_lpo_fecha_vigencia"] = obj["certificacion_lpo_fecha_vigencia"] !== ''
                                                ? "LPO " + obj["certificacion_lpo_fecha_vigencia"] + (obj["certificacion_lpo_en_vigencia"]
                                                    ? " Vigencia"
                                                    : obj["certificacion_lpo_en_aniversario"]
                                                        ? " Aniversario"
                                                        : "")
                                                : ''

                                            obj["certificacion_opr_fecha_vigencia"] = obj["certificacion_opr_fecha_vigencia"] !== ''
                                                ? "OPR " + obj["certificacion_opr_fecha_vigencia"] + (obj["certificacion_opr_en_vigencia"]
                                                    ? " Vigencia"
                                                    : obj["certificacion_opr_en_aniversario"]
                                                        ? " Aniversario"
                                                        : "")
                                                : ''

                                            obj["certificacion_nop_fecha_vigencia"] = obj["certificacion_nop_fecha_vigencia"] !== ''
                                                ? "NOP " + obj["certificacion_nop_fecha_vigencia"] + (obj["certificacion_nop_en_vigencia"]
                                                    ? " Vigencia"
                                                    : obj["certificacion_nop_en_aniversario"]
                                                        ? " Aniversario"
                                                        : "")
                                                : ''

                                            obj["certificacion_otro_fecha_vigencia"] = obj["certificacion_otro_fecha_vigencia"] !== ''
                                                ? obj["certificacion_otro_text"] + " " + obj["certificacion_otro_fecha_vigencia"] + (obj["certificacion_otro_en_vigencia"]
                                                    ? " Vigencia"
                                                    : obj["certificacion_otro_en_aniversario"]
                                                        ? " Aniversario"
                                                        : "")
                                                : ''
                                        } else if (this.formId === 232) {
                                            obj["certificacion_lpo_fecha_vigencia"] = obj["certificacion_lpo_fecha_vigencia"] !== ''
                                                ? "LPO " + obj["certificacion_lpo_fecha_vigencia"] + (obj["certificacion_lpo_en_vigencia"]
                                                    ? " Vigencia"
                                                    : obj["certificacion_lpo_en_aniversario"]
                                                        ? " Aniversario"
                                                        : "")
                                                : ''

                                            obj["certificacion_cor_fecha_vigencia"] = obj["certificacion_cor_fecha_vigencia"] !== ''
                                                ? "COR " + obj["certificacion_cor_fecha_vigencia"] + (obj["certificacion_cor_en_vigencia"]
                                                    ? " Vigencia"
                                                    : obj["certificacion_cor_en_aniversario"]
                                                        ? " Aniversario"
                                                        : "")
                                                : ''

                                            obj["certificacion_nop_fecha_vigencia"] = obj["certificacion_nop_fecha_vigencia"] !== ''
                                                ? "NOP " + obj["certificacion_nop_fecha_vigencia"] + (obj["certificacion_nop_en_vigencia"]
                                                    ? " Vigencia"
                                                    : obj["certificacion_nop_en_aniversario"]
                                                        ? " Aniversario"
                                                        : "")
                                                : ''

                                            obj["certificacion_otro_fecha_vigencia"] = obj["certificacion_otro_fecha_vigencia"] !== ''
                                                ? obj["certificacion_otro_text"] + " " + obj["certificacion_otro_fecha_vigencia"] + (obj["certificacion_otro_en_vigencia"]
                                                    ? " Vigencia"
                                                    : obj["certificacion_otro_en_aniversario"]
                                                        ? " Aniversario"
                                                        : "")
                                                : ''
                                        }

                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);
                                break

                            default:
                                this.showDoc = false
                                this.showForm = true
                                this.docloading = false
                                if (this.submission !== null) {
                                    let lastForm = {}
                                    for (var i in this.submission) {
                                        const obj = this.submission[i]
                                        lastForm[obj.name] = obj.value
                                    }
                                    setTimeout(() => {
                                        this.communication.emitEvent({
                                            identifier: 'set-values',
                                            form: lastForm
                                        })
                                    }, 100);
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                }, 100);

                                break
                        }

                        if (doc) {
                            /* const item = "review" + this.formId + this.reviewId
                            const tmpURL = localStorage.getItem(item)

                            if (tmpURL) {
                                this.getDocumentByURL(tmpURL, doc, item)
                            } else {
                                this.buildDocument(doc, item)
                            } */

                            this.buildDocument(doc, "")
                        }
                    },
                    error => {
                        this.alertService.error(error);
                    })
        }

        this.form = this.formBuilder.group({
            password: ['', Validators.required],
            comments: ['']
        });

        this.formOrder = this.formBuilder.group({
            password: ['', Validators.required],
            comments: ['', Validators.required]
        });
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }
    get fOrder() { return this.formOrder.controls; }

    onSubmit() {
        this.submitted = true;
        this.saving = true
        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        const formsFSOL08s = [12, 24, 25, 22, 23, 18, 212, 272, 273, 224, 225, 222, 274, 223, 275, 218, 276]
        const formsFSOL07s = [30, 230]

        if (this.form.value.password && !this.toSave) {
            this.loading = true;
            this.forbiddenId = false
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    async data => {
                        this.alertService.clear()
                        if (data.validated) {
                            this.alertService.success('Contraseña valida', { autoClose: true })

                            if (this.isEvaluation) {
                                //console.log("esta entrando aqui")
                                // Save form
                                await this.communication.emitEvent({
                                    identifier: 'submit'
                                })

                                this.evaluationService.signEvaluation(this.reviewId, this.formId)
                                    .pipe(first())
                                    .subscribe(
                                        response => {
                                            this.saving = false
                                            this.modal.close(true)
                                            this.modal.dismiss()
                                        },
                                        error => {
                                            this.alertService.error(error);
                                        })
                            } else if (this.isApplication) {
                                this.requestsService.signDocument(this.reviewId, this.formId)
                                    .pipe(first())
                                    .subscribe(
                                        response => {
                                            this.saving = false
                                            this.modal.close(true)
                                            this.modal.dismiss()
                                        },
                                        error => {
                                            this.alertService.error(error);
                                        })
                            } else {
                                if (formsFSOL08s.includes(this.formId)) {
                                    //console.log("es 08")
                                    // Save form
                                    await this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                } else if(formsFSOL07s.includes(this.formId)) {
                                    //console.log("es 07")
                                    // Save form
                                    await this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                } else {
                                    //console.log("no es 08 ni 07")
                                    // Save form
                                    if (this.showForm) {
                                        await this.communication.emitEvent({
                                            identifier: 'submit'
                                        })
                                    } else if (this.showDoc) {
                                        this.reviewsService.signReview(this.reviewId, this.formId)
                                            .pipe(first())
                                            .subscribe(
                                                response => {
                                                    this.saving = false
                                                    this.modal.close(true)
                                                    this.modal.dismiss()
                                                },
                                                error => {
                                                    this.alertService.error(error);
                                                })
                                    }
                                }
                            }
                        } else {
                            this.forbiddenId = true
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }

    }

    deny() {
        //console.log("SUBMITT")
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        this.formOrder.controls["comments"].setValidators([Validators.required])
        this.formOrder.controls["comments"].updateValueAndValidity()

        // Stop here if form is invalid
        if (this.formOrder.invalid) {
            return;
        }
        if (this.formOrder.value.password) {
            this.forbiddenId = false
            this.loading = true;
            this.authenticationService.isPasswordValidated(this.formOrder.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear()
                        if (data.validated) {
                            this.alertService.success('Contraseña valida', { autoClose: true })
                            this.inspectionService.denyInspection(this.inspectionId, this.formOrder.value.comments)
                                .pipe(first())
                                .subscribe(
                                    data => {
                                        this.forbiddenId = false
                                        this.alertService.success('Inspeccion denegada', { autoClose: true })
                                        this.modal.close(true)
                                        this.modal.dismiss()
                                    },
                                    error => {
                                        this.alertService.error(error);
                                        this.loading = false;
                                    }
                                )
                        } else {
                            this.forbiddenId = true
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }
    }

    approve() {
        //console.log("SUBMITT")
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        this.formOrder.controls["comments"].clearValidators()
        this.formOrder.controls["comments"].updateValueAndValidity()

        // Stop here if form is invalid
        if (this.formOrder.invalid) {
            return;
        }
        if (this.formOrder.value.password) {
            this.forbiddenId = false
            this.loading = true;
            this.authenticationService.isPasswordValidated(this.formOrder.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear()
                        if (data.validated) {
                            this.alertService.success('Contraseña valida', { autoClose: true })
                            this.inspectionService.acceptInspection(this.inspectionId)
                                .pipe(first())
                                .subscribe(response => {
                                    this.forbiddenId = false
                                    this.alertService.success('Inspeccion aprovada', { autoClose: true })
                                    this.modal.close(true)
                                    this.modal.dismiss()
                                },
                                    error => {
                                        this.alertService.error(error);
                                        this.loading = false;
                                    })
                        } else {
                            this.forbiddenId = true
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }
    }

    // Get object data from requirements
    getValueListRequirements(requirements, name) {
        var value = null
        for (const i in requirements) {
            const element = requirements[i]
            if (element.requiredInto === name) {
                value = element.data
            }
        }
        if (value) return value
        else return []
    }

    // Get value from submission
    getValueSubmission(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    // Get document by URL
    async getDocumentByURL(tmpURL, document, itemName) {
        await this.inspectionService.getUrlStatus(tmpURL)
            .pipe(first())
            .subscribe(
                data => {
                    //console.log(data)
                },
                error => {
                    //console.log(error)
                    if (error && error === 'OK') {
                        this.url = tmpURL
                        this.docloading = false
                    } else {
                        this.buildDocument(document, itemName)
                    }
                }
            )
    }

    // Build document
    buildDocument(document, itemName) {
        /* const blob2 = await Packer.toBlob(document)
        saveAs(blob2, "document") */

        Packer.toBlob(document).then(blob => {
            this.bdoc = blob
            this.reviewsService.uploadPreview(this.bdoc, 'docx').subscribe(data => {
                if (data.success) {
                    const u = data.url

                    //console.log(u, "evaluation")
                    //localStorage.setItem(itemName, u)

                    setTimeout(() => {
                        this.url = u
                        this.docloading = false
                    }, 3000);
                }
            }, error => {
                console.log(error)
            })
        });
    }
}